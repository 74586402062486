import styled from 'styled-components'
import theme from 'styles/theme'
import { themeGet } from 'styled-system'
import { rem } from 'polished'

export const SectionHeaderWrapper = styled.div`
  width: 100%;
  display: block;

  ${(props) =>
    props.hasImage &&
    `
    * {
      color: white !important;
    }
  `}


  ${(props) =>
    props.isPageHeader &&
    `margin-top: 60px;

    ${theme.mediaQueries.large} {
      margin-top: 100px;
    }
  `}

  background: ${(props) =>
    props.background === 'Grey' ? themeGet('colors.sagelight') : 'transparent'};
  > div {
    ${(props) => props.withMaxWidth && `max-width: 780px;`}
    display: flex;
    flex-direction: column;
    text-align: center;
    position: relative;
    width: 90%;
    margin: 0 auto;
    padding: 0px ${theme.space[3]};

    ${theme.mediaQueries.large} {
      padding: 0px ${theme.space[3]};
    }
  }
`

export const SectionHeaderCopy = styled.div`
  color: ${theme.colors.sagemedium};
  margin: 0 auto;
  max-width: 600px;
  text-align: inherit;

  p {
    &:first-child {
      margin-top: 0;
    }

    &:last-child {
      margin-bottom: 0;
    }
  }

  hr {
    border: 0 none;
    display: block;
    font-size: ${rem(10)};
    height: ${rem(24)};
    line-height: ${rem(24)};
    margin: ${rem(16)} 0;

    &::before {
      content: '\u25C6';
    }
  }
`

export const SectionHeaderCta = styled.div`
  a > span {
    margin-top: 5px;
  }

  a {
    width: 100%;
    ${theme.mediaQueries.small} {
      width: auto;
    }
  }
`

export default {
  SectionHeaderWrapper,
  SectionHeaderCta,
}
