import React from 'react'
import PropTypes from 'prop-types'
import { Eyebrow, Title70, Title60 } from 'components/Typography'
import Button from 'components/Button'
import Link from 'components/Link'
import MarkdownRenderer from 'components/MarkdownRenderer'
import trackGTM from 'utils/trackGTM'
import { useComponentId, useFieldId } from 'utils/optimizely'
import { Spacing10, Spacing30, Spacing20, Spacing50 } from 'styles/sharedStyle'
import {
  SectionHeaderCopy,
  SectionHeaderCta,
  SectionHeaderWrapper,
} from './SectionHeader.style'
import { fixAudiences } from '../../utils/audiences'

const SectionHeader = ({
  title,
  eyebrow,
  content,
  background,
  withMaxWidth,
  isPageHeader,
  ctaLabel,
  ctaUrl,
  audiences,
  hasImage,
  parentElementId,
}) => {
  const Headline = isPageHeader ? Title70 : Title60

  // Content can either be markdown, or plain text.
  const { childMarkdownRemark: { html: contentHTML } = {} } = content || {}
  const hasContent = !!contentHTML || (content && typeof content === 'string')

  const hasEyebrow = !!eyebrow
  const hasHeadline = !!title
  const hasCTA = !!(ctaLabel && ctaUrl)
  const hasAudiences = !!audiences

  const elementId = parentElementId
    ? `${parentElementId}-Comp_section_header_SCH01`
    : useComponentId(`Comp_section_header_SCH01`)

  return (
    <>
      {(hasHeadline || hasEyebrow || hasContent) && (
        <SectionHeaderWrapper
          id={elementId}
          background={background}
          withMaxWidth={withMaxWidth}
          isPageHeader={isPageHeader}
          hasAudience={hasAudiences}
          className={`${fixAudiences(audiences)}`}
          hasImage={hasImage}>
          <Spacing50 />
          <div>
            {hasEyebrow && (
              <>
                <Eyebrow as="span" id={useFieldId(elementId, `eyebrow`)}>
                  {eyebrow}
                </Eyebrow>
                {(hasHeadline || hasContent || hasCTA) && <Spacing10 />}
              </>
            )}
            {hasHeadline && (
              <>
                {hasHeadline && (
                  <Headline as="h2" id={useFieldId(elementId, `headline`)}>
                    {title}
                  </Headline>
                )}
                {(hasContent || hasCTA) && <Spacing30 />}
              </>
            )}
            {hasContent && (
              <>
                {contentHTML ? (
                  <SectionHeaderCopy
                    as={MarkdownRenderer}
                    html={contentHTML}
                    id={useFieldId(elementId, `copy`)}
                  />
                ) : (
                  <SectionHeaderCopy id={useFieldId(elementId, `copy`)}>
                    {content}
                  </SectionHeaderCopy>
                )}

                {hasCTA && <Spacing20 />}
              </>
            )}
            {hasCTA && (
              <>
                <SectionHeaderCta>
                  <Button
                    id={useFieldId(elementId, `cta`)}
                    onClick={() => {
                      trackGTM(
                        'cta interaction',
                        'cta interaction',
                        'in page cta',
                        contentHTML || ctaLabel
                      )
                    }}
                    as={Link}
                    to={ctaUrl}
                    level="primary"
                    fixedSize>
                    {ctaLabel}
                  </Button>
                </SectionHeaderCta>
              </>
            )}
          </div>
          <Spacing50 />
        </SectionHeaderWrapper>
      )}
    </>
  )
}

SectionHeader.propTypes = {
  title: PropTypes.string,
  eyebrow: PropTypes.string,
  content: PropTypes.oneOfType([
    PropTypes.shape({
      childMarkdownRemark: PropTypes.shape({
        html: PropTypes.string,
      }),
    }),
    PropTypes.string,
  ]),
  background: PropTypes.string,
  withMaxWidth: PropTypes.bool,
  isPageHeader: PropTypes.bool,
  ctaLabel: PropTypes.string,
  ctaUrl: PropTypes.string,
  audiences: PropTypes.string,
  hasImage: PropTypes.bool,
  parentElementId: PropTypes.string,
}

SectionHeader.defaultProps = {
  title: '',
  eyebrow: '',
  content: {},
  background: 'transparent',
  withMaxWidth: false,
  isPageHeader: false,
  ctaLabel: '',
  ctaUrl: '',
  audiences: '',
  hasImage: false,
  parentElementId: '',
}

export default SectionHeader
